<template>
  <IssueFilter
    class="IssueFilterTotalScore"
    :class="{ 'IssueFilterTotalScore--dark': dark }"
    :title="$t('filter.Score')"
    :show-more="showMore"
    :dark="dark"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-row
      dense
      class="flex px-8"
      :class="showMore ? 'pb-4' : 'pb-2'"
    >
      <v-col
        v-for="score in SCORE"
        :key="score.value"
        class="IssueFilterTotalScore__col pb-1"
      >
        <ColorScore
          v-ripple="{ class: 'white--text' }"
          :score="score.value"
          :inactive="!model.includes(score.value)"
          :dark="dark"
          :inactive-color="dark ? '#38364D' : '#F5F5F9'"
          :inactive-hover-color="dark ? '#343246' : '#F1F1F6'"
          class="IssueFilterTotalScore__badge"
          tabindex="0"
          @click="setTotalScore(score.value, !model.includes(score.value))"
          @keyup.enter="setTotalScore(score.value, !model.includes(score.value))"
          @keyup.space="setTotalScore(score.value, !model.includes(score.value))"
        >
          <template
            v-if="!dark"
            #default="{ inactive, label }"
          >
            <span
              v-if="!dark"
              class="mr-auto"
              :class="{ 'white--text': !inactive }"
              :style="inactive ? { color: '#8B90A0' } : {}"
              v-text="label"
            />
          </template>
          <template
            v-if="projectId != null"
            #append
          >
            <span
              class="IssueFilterTotalScore__counter ml-2"
              :style="{
                color: model.includes(score.value)
                  ? 'rgba(255, 255, 255, 0.7)'
                  : '#8B90A0' }"
              v-text="getCounter(score.value)"
            />
          </template>
        </ColorScore>
      </v-col>
    </v-row>

    <slot name="more" />
  </IssueFilter>
</template>

<script>
import * as R from 'ramda'

import { SCORE } from '../constants'

import IssueCounter from '../store/orm/issueCounter'

import IssueFilter from '../layouts/IssueFilter'
import ColorScore from './ColorScore'

const NO_COUNTER = '…'

export default {
  name: 'IssueFilterTotalScore',

  components: {
    ColorScore,
    IssueFilter,
  },

  inheritAttrs: false,

  props: {
    projectId: { type: String, default: null },

    // e.g.: [1, 2]
    value: { type: Array, default: () => [] },

    filter: { type: [Object, Symbol], default: () => ({}) },
    additiveFilters: { type: Boolean, default: true },

    showMore: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
  },

  data() {
    return {
      SCORE,

      model: [...this.value].map(Number),
    }
  },

  computed: {
    counters() {
      const { projectId, filter, additiveFilters } = this
      return IssueCounter.getOrDefault(projectId, filter, additiveFilters)
    },
  },

  watch: {
    // v-model
    value(v) {
      if (R.equals(v, this.model)) return
      this.model = [...v].map(Number)
    },
    model(v) {
      if (R.equals(v, this.value)) return
      this.$emit('input', [...v])
    },
  },

  methods: {
    getCounter(scoreValue) {
      return this.counters.totalScore?.[scoreValue] ?? NO_COUNTER
    },

    setTotalScore(score, isSelected) {
      const { model } = this
      if (isSelected) {
        if (!model.includes(score)) model.push(score)
      } else {
        let ix = -1
        while ((ix = model.indexOf(score)) !== -1) model.splice(ix, 1)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueFilterTotalScore
  &__badge
    cursor: pointer
    width: 100%

  &--dark &__counter
    color: rgba(255, 255, 255, .7)
</style>
