<template>
  <IssueFilter
    class="IssueFilterStatus"
    :title="$t('filter.RemediationStatus')"
    :dark="dark"
    :sticky-top="stickyTop"
  >
    <IssueFilterCheckbox
      v-for="status in issueStatus"
      :key="status.name"
      class="IssueFilterStatus__status"
      :value="model.includes(status.name)"
      :counter="projectId && getCounter(status.name)"
      :dark="dark"
      @input="setStatus(status.name, $event)"
    >
      <v-tooltip
        open-delay="300"
        bottom
      >
        <template #activator="{ on, attrs }">
          <div
            class="d-flex align-center text-truncate"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              :color="issueStatusColor(status.name)"
              :size="20"
              v-text="status.icon"
            />
            <span
              class="ml-3 text-truncate"
              :class="{ 'white--text': dark }"
              v-text="status.displayName"
            />
          </div>
        </template>
        <span v-text="status.displayName" />
      </v-tooltip>
    </IssueFilterCheckbox>
  </IssueFilter>
</template>

<script>
import * as R from 'ramda'

import { PREDEFINED_STATUSES, PREDEFINED_STATUS_LOOKUP } from '../constants'

import IssueCounter from '../store/orm/issueCounter'

import IssueFilter from '../layouts/IssueFilter'
import IssueFilterCheckbox from './IssueFilterCheckbox'
import i18n from '../i18n'

const NO_COUNTER = '…'

export default {
  name: 'IssueFilterStatus',

  components: {
    IssueFilter,
    IssueFilterCheckbox,
  },

  props: {
    projectId: { type: String, default: null },

    // e.g.: ['fix_confirmed', 'fix_unconfirmed']
    value: { type: Array, default: () => [] },

    filter: { type: [Object, Symbol], default: () => ({}) },
    additiveFilters: { type: Boolean, default: true },

    dark: { type: Boolean, default: false },
    stickyTop: { type: String, default: '0' },
  },

  data() {
    return {
      model: [...this.value],
    }
  },

  computed: {
    issueStatus() {
      const { $store, projectId } = this
      return projectId
        ? $store.getters['$issueStatus/getList'](projectId)
        : PREDEFINED_STATUSES.map((status) => ({ ...status, displayName: i18n.t(status.displayName) }))
    },
    issueStatusLookup() {
      const { $store, projectId } = this
      return projectId
        ? $store.getters['$issueStatus/getLookup'](projectId)
        : PREDEFINED_STATUS_LOOKUP
    },

    counters() {
      const { projectId, filter, additiveFilters } = this
      return IssueCounter.getOrDefault(projectId, filter, additiveFilters)
    },
  },

  watch: {
    // v-model
    value(v) {
      if (R.equals(v, this.model)) return
      this.model = [...v]
    },
    model(v) {
      if (R.equals(v, this.value)) return
      this.$emit('input', [...v])
    },
  },

  methods: {
    // issueStatusColor :: str -> str
    // issueStatusColor('new_issue') === '#123456'
    // issueStatusColor('some-new-status') === 'grey' // default color
    issueStatusColor(status) {
      return this.issueStatusLookup[status]?.color || 'grey'
    },

    getCounter(status) {
      return this.counters.status?.[status] ?? NO_COUNTER
    },

    setStatus(status, isSelected) {
      const { model } = this
      if (isSelected) {
        if (!model.includes(status)) model.push(status)
      } else {
        let ix = -1
        while ((ix = model.indexOf(status)) !== -1) model.splice(ix, 1)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
// .IssueFilterStatus
</style>
