<template>
  <IssueFilter
    class="IssueFilterGroupsAndProjects"
    :title="$t('filter.Projects')"
    :dark="dark"
    :sticky-top="stickyTop"
    :show-more-button="hasMoreItems"
    :show-more-text="$t('filter.ShowAll')"
    :show-more.sync="showAll"
  >
    <template v-for="item in displayedItems">
      <IssueFilterGroupsAndProjectsGroup
        v-if="item.isGroup"
        :key="item.id"
        class="IssueFilterGroupsAndProjects__item IssueFilterGroupsAndProjects__item--group"
        :group="item.group"
        :limit-children="item.limitChildren"
        :dark="dark"
        :selected-group-ids="value.selectedGroupIds"
        :selected-project-ids="value.selectedProjectIds"
        @toggle="toggleGroupSimple(item.id)"
        @toggle-group="toggleNestedGroup($event.groupId)"
        @toggle-project="toggleNestedProject($event.projectId)"
      />
      <IssueFilterGroupsAndProjectsProject
        v-else-if="item.isProject"
        :key="item.id"
        class="IssueFilterGroupsAndProjects__item IssueFilterGroupsAndProjects__item--project"
        :project="item.project"
        :dark="dark"
        :selected="value.selectedProjectIds.includes(item.id)"
        @toggle="toggleProjectSimple(item.id)"
      />
    </template>
  </IssueFilter>
</template>

<script>
import * as R from 'ramda'

import ProjectGroup from '../store/orm/projectGroup'
import Project from '../store/orm/project'

import IssueFilter from '../layouts/IssueFilter'

import IssueFilterGroupsAndProjectsGroup from './IssueFilterGroupsAndProjectsGroup'
import IssueFilterGroupsAndProjectsProject from './IssueFilterGroupsAndProjectsProject'

const SHOW_MORE_LIMIT = 10

export default {
  name: 'IssueFilterGroupsAndProjects',

  components: {
    IssueFilter,
    IssueFilterGroupsAndProjectsGroup,
    IssueFilterGroupsAndProjectsProject,
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        selectedGroupIds: [],
        selectedProjectIds: [],
      }),
    }, // v-model
    dark: { type: Boolean, default: false },
    stickyTop: { type: String, default: '0' },
  },

  data() {
    return {
      showAll: false,
    }
  },

  computed: {
    rootGroup() {
      return ProjectGroup.getRootGroupQ().first() || null
    },

    allItems() {
      const { rootGroup } = this
      return rootGroup && rootGroup
        .getSortedDescendants()
        .map((projectOrGroup) => {
          const isProject = projectOrGroup instanceof Project
          const isGroup = projectOrGroup instanceof ProjectGroup
          if (!isProject && !isGroup) throw new Error('TypeError')
          return {
            id: projectOrGroup.id,
            name: projectOrGroup.name,
            isProject,
            isGroup,
            project: isProject ? projectOrGroup : null,
            group: isGroup ? projectOrGroup : null,
            limitChildren: null,
          }
        })
    },

    hasMoreItems() {
      const { rootGroup } = this
      return rootGroup && rootGroup.countDescendants() > SHOW_MORE_LIMIT
    },

    displayedItems() {
      const { allItems, hasMoreItems, showAll } = this
      if (!allItems) return null
      if (!hasMoreItems || showAll) return allItems

      let limitLeft = SHOW_MORE_LIMIT
      return allItems
        .map(item => {
          if (limitLeft <= 0) return null
          --limitLeft
          if (item.isProject) return item
          const rv = { ...item, limitChildren: limitLeft }
          limitLeft = Math.max(0, limitLeft - item.group.countDescendants())
          return rv
        })
        .filter(Boolean)
    },
  },

  methods: {
    toggleProjectSimple(projectId, { selectedProjectIds = null, selectedGroupIds = null, emit = true } = {}) {
      selectedProjectIds = selectedProjectIds ?? this.value.selectedProjectIds
      selectedGroupIds = selectedGroupIds ?? this.value.selectedGroupIds

      // just toggles project selection, ignores nesting,
      // so it works fine with top level projects
      const newSelection = selectedProjectIds.includes(projectId)
        ? R.without([projectId], selectedProjectIds)
        : R.append(projectId, selectedProjectIds)
      const newValue = { selectedProjectIds: newSelection, selectedGroupIds }
      if (emit) this.input(newValue)
      return newValue
    },

    toggleGroupSimple(groupId, { selectedProjectIds = null, selectedGroupIds = null, emit = true } = {}) {
      selectedProjectIds = selectedProjectIds ?? this.value.selectedProjectIds
      selectedGroupIds = selectedGroupIds ?? this.value.selectedGroupIds

      // just toggles group selection, deselects all children,
      // so it works fine with top level groups.
      // Children are still displayed as selected or deselected (because they inherit their selection
      // state from the group)
      const wasSelected = selectedGroupIds.includes(groupId)
      let newSelection
      if (wasSelected) {
        newSelection = R.without([groupId], selectedGroupIds)
      } else {
        newSelection = R.append(groupId, selectedGroupIds)
      }

      // fetch children to deselect
      const group = ProjectGroup.find(groupId)
      const excludeProjectIds = []
      const excludeGroupIds = []
      for (const child of group.getAllDescendants({ includeProjects: true })) {
        if (child instanceof Project) excludeProjectIds.push(child.id)
        else if (child instanceof ProjectGroup) excludeGroupIds.push(child.id)
      }

      const newValue = {
        selectedProjectIds: R.without(excludeProjectIds, selectedProjectIds),
        selectedGroupIds: R.without(excludeGroupIds, newSelection),
      }
      if (emit) this.input(newValue)
      return newValue
    },

    toggleNestedGroup(groupId) {
      const { value: { selectedGroupIds } } = this
      const group = ProjectGroup.find(groupId)
      const closestSelectedParent = group
        .getPath({ includeRoot: false, includeSelf: false, topToBottom: false })
        .find(g => selectedGroupIds.includes(g.id))

      if (closestSelectedParent != null) {
        // Parent group is selected, thus this group inherits selected state from this parent.
        // We want to toggle it (make not selected), then select all other children
        let ctx = this.toggleGroupSimple(closestSelectedParent.id, { emit: false })
        console.warn(`Turned off ${closestSelectedParent.name}`, JSON.stringify(ctx))
        const turnOnRestOfChildren = (groupToRecheck) => {
          for (const project of groupToRecheck.getProjectsQ().all()) {
            ctx = this.toggleProjectSimple(project.id, { ...ctx, emit: false })
            console.warn(`Turned back on ${project.name}`, JSON.stringify(ctx))
          }
          for (const childGroup of groupToRecheck.getChildrenQ().all()) {
            if (childGroup.id === groupId) continue
            // fixme: too algorithmically complex, but should be correct
            if (childGroup.getAllDescendants({ includeProjects: false }).some(g => g.id === groupId)) {
              console.warn(`Going to: turn back on some children of ${childGroup.name}`, JSON.stringify(ctx))
              turnOnRestOfChildren(childGroup)
              console.warn(`Turned back on some children of ${childGroup.name}`, JSON.stringify(ctx))
            } else {
              ctx = this.toggleGroupSimple(childGroup.id, { ...ctx, emit: false })
              console.warn(`Turned back on ${childGroup.name}`, JSON.stringify(ctx))
            }
          }
        }
        turnOnRestOfChildren(closestSelectedParent)
        this.input(ctx)
      } else {
        this.toggleGroupSimple(groupId)
      }
    },

    toggleNestedProject(projectId) {
      const { value: { selectedGroupIds } } = this
      const project = Project.query().with('group').find(projectId)
      const closestSelectedParent = project
        .group
        .getPath({ includeRoot: false, includeSelf: true, topToBottom: false })
        .find(g => selectedGroupIds.includes(g.id))

      if (closestSelectedParent != null) {
        // Parent group is selected, thus this group inherits selected state from this parent.
        // We want to toggle it (make not selected), then select all other children
        let ctx = this.toggleGroupSimple(closestSelectedParent.id, { emit: false })
        console.warn(`Turned off ${closestSelectedParent.name}`, JSON.stringify(ctx))
        const turnOnRestOfChildren = (groupToRecheck) => {
          for (const project of groupToRecheck.getProjectsQ().all()) {
            if (project.id === projectId) continue
            ctx = this.toggleProjectSimple(project.id, { ...ctx, emit: false })
            console.warn(`Turned back on ${project.name}`, JSON.stringify(ctx))
          }
          for (const childGroup of groupToRecheck.getChildrenQ().all()) {
            // fixme: too algorithmically complex, but should be correct
            if (childGroup.getAllDescendants({ includeProjects: true }).some(x => x.id === projectId)) {
              console.warn(`Going to: turn back on some children of ${childGroup.name}`, JSON.stringify(ctx))
              turnOnRestOfChildren(childGroup)
              console.warn(`Turned back on some children of ${childGroup.name}`, JSON.stringify(ctx))
            } else {
              ctx = this.toggleGroupSimple(childGroup.id, { ...ctx, emit: false })
              console.warn(`Turned back on ${childGroup.name}`, JSON.stringify(ctx))
            }
          }
        }
        turnOnRestOfChildren(closestSelectedParent)
        this.input(ctx)
      } else {
        this.toggleProjectSimple(projectId)
      }
    },

    input({ selectedGroupIds, selectedProjectIds }) {
      const findFullySelectedGroups = () =>
        ProjectGroup
          .query()
          .with('children')
          .with('projects')
          .all()
          .filter(g => g.children.length || g.projects.length)
          .filter(g => g.children.every(child => selectedGroupIds.includes(child.id)))
          .filter(g => g.projects.every(p => selectedProjectIds.includes(p.id)))
      let groupsToOptimize
      while (true) {
        groupsToOptimize = findFullySelectedGroups()
        if (!groupsToOptimize.length) break
        for (const g of groupsToOptimize) {
          if (!selectedGroupIds.includes(g.id)) selectedGroupIds.push(g.id)
          const excludeProjectIds = []
          const excludeGroupIds = []
          for (const child of g.getAllDescendants({ includeProjects: true })) {
            if (child instanceof Project) excludeProjectIds.push(child.id)
            else if (child instanceof ProjectGroup) excludeGroupIds.push(child.id)
          }
          selectedGroupIds = R.without(excludeGroupIds, selectedGroupIds)
          selectedProjectIds = R.without(excludeProjectIds, selectedProjectIds)
        }
      }

      this.$emit('input', { selectedGroupIds, selectedProjectIds })
    },
  },
}
</script>
