var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'app-ripple' }),expression:"{ class: 'app-ripple' }"}],staticClass:"IssueFilterCheckbox",class:{
    'IssueFilterCheckbox--active': _vm.value,
    'IssueFilterCheckbox--dark': _vm.dark,
  },attrs:{"tabindex":"0"},on:{"click":function($event){_vm.model = !_vm.model},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.model = !_vm.model},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;_vm.model = !_vm.model}]}},[_c('span',{staticClass:"IssueFilterCheckbox__label"},[_vm._t("default")],2),(_vm.counter != null)?_c('span',{staticClass:"IssueFilterCheckbox__counter ml-auto",domProps:{"textContent":_vm._s(_vm.counter)}}):_vm._e(),_c('v-simple-checkbox',{staticClass:"IssueFilterCheckbox__checkbox ml-2 mt-0 pt-0",class:{
      'ml-2': _vm.counter != null,
      'ml-auto': _vm.counter == null,
    },attrs:{"color":_vm.dark ? 'darkPrimary' : 'primary',"indeterminate":_vm.indeterminate,"indeterminate-icon":"mdi-checkbox-intermediate-variant"},on:{"click":function($event){$event.stopPropagation();/* prevent parent click handler */}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }