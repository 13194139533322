<template>
  <IssueFilter
    v-if="allPorts && allPorts.length"
    class="IssueFilterPort"
    :title="$t('filter.Ports')"
    :show-more-button="showExpandToggle"
    :show-more.sync="expand"
    :dark="dark"
    :sticky-top="stickyTop"
  >
    <IssueFilterCheckbox
      v-for="port in visiblePorts"
      :key="port"
      v-intersect="(entries, observer) => onIntersect(port, entries, observer)"
      :value="model.includes(port)"
      :counter="projectId && getCounter(port)"
      :dark="dark"
      class="IssueFilterPort__port"
      @input="setPortSelected(port, !model.includes(port))"
    >
      <span
        class="text-truncate"
        :class="{ 'white--text': dark }"
        v-text="port"
      />
    </IssueFilterCheckbox>
  </IssueFilter>
</template>

<script>
import axios from 'axios'
import * as R from 'ramda'
import { debounce } from 'throttle-debounce'

import { EMPTY_SET } from '../constants'
import { removeInPlace, unorderedPlainObjectHash } from '../helpers'

import IssueCounter from '../store/orm/issueCounter'

import IssueFilter from '../layouts/IssueFilter'
import IssueFilterCheckbox from './IssueFilterCheckbox'

const getVisibleSlice = (
  items,
  alwaysDisplayWhen = () => false,
  defaultNumber,
) => {
  const [alwaysVisible, rest] = R.partition(alwaysDisplayWhen, items)
  if (alwaysVisible.length >= defaultNumber) return alwaysVisible
  return [
    ...alwaysVisible,
    ...rest.slice(0, defaultNumber - alwaysVisible.length),
  ]
}

const DEFAULT_PORT_LINES = 5
const NO_COUNTER = '…'

export default {
  name: 'IssueFilterPort',

  components: {
    IssueFilter,
    IssueFilterCheckbox,
  },

  props: {
    projectId: { type: String, required: true },

    // e.g.: [80, 21]
    value: { type: Array, default: () => [] },

    filter: { type: [Object, Symbol], default: () => ({}) },
    additiveFilters: { type: Boolean, default: true },

    dark: { type: Boolean, default: false },
    stickyTop: { type: String, default: '0' },
  },

  data() {
    return {
      model: [...this.value],

      expand: false,
      portsInViewport: [],
    }
  },

  computed: {
    filterHash() {
      const { filter } = this
      return filter && unorderedPlainObjectHash(filter)
    },

    counters() {
      const { projectId, filter, additiveFilters } = this
      return IssueCounter.getOrDefault(projectId, filter, additiveFilters)
    },

    allPorts() {
      const { $store, projectId } = this
      return $store.getters['projectsSettings/allPorts'](projectId)
    },

    collapsedPorts() {
      const { allPorts } = this
      return allPorts && getVisibleSlice(allPorts, this.isSelected, DEFAULT_PORT_LINES)
    },

    visiblePorts() {
      const { allPorts, collapsedPorts, expand } = this
      if (!allPorts || !collapsedPorts) return null

      const toShow = expand
        ? allPorts
        : collapsedPorts
      return R.sortWith([
        R.descend(this.isSelected),
      ], toShow)
    },

    showExpandToggle() {
      const { allPorts, collapsedPorts } = this

      if (!allPorts?.length || !collapsedPorts) return null
      return allPorts.length > collapsedPorts.length
    },
  },

  watch: {
    // v-model
    value(v) {
      if (R.equals(v, this.model)) return
      this.model = [...v]
    },
    model(v) {
      if (R.equals(v, this.value)) return
      this.$emit('input', [...v])
    },

    filterHash: 'fetchCounters',

    portsInViewport: {
      handler: 'fetchCounters',
      immediate: true,
    },

    visiblePorts(ports) {
      if (!ports) return
      this.portsInViewport = R.uniq([
        ...this.collapsedPorts,
        ...this.portsInViewport
          .filter(port => ports.includes(port)),
      ])
    },

    async expand(isOpen) {
      if (isOpen) {
        await this.$nextTick()
        this.$el.querySelector('.IssueFilter__title') // eslint-disable-line
          ?.scrollIntoView?.(true)
      }
    },
  },

  methods: {
    getCounter(port) {
      if (this.filter === EMPTY_SET) return 0
      return this.counters.ports?.[port] ?? NO_COUNTER
    },

    setPortSelected(port, isSelected) {
      const { model } = this
      if (isSelected) {
        if (!model.includes(port.toString())) model.push(port.toString())
      } else {
        this.model = R.reject(R.equals(port.toString()), model)
      }
    },

    onIntersect(port, entries/*, observer */) {
      const { portsInViewport } = this
      const target = entries[0]

      if (!target.isIntersecting) {
        removeInPlace(portsInViewport, port)
      } else {
        if (!portsInViewport.includes(port)) portsInViewport.push(port)
      }
    },

    isSelected(port) { return this.model.includes(port.toString()) },

    async fetchCounter(port) {
      const { projectId, filter, additiveFilters: additive } = this
      if (filter === EMPTY_SET) return
      return await IssueCounter.dispatch(
        '$countPort',
        { projectId, port: port.toString(), filter, additive },
      )
        .catch((e) => axios.isCancel(e) ? console.info(e) : console.error(e))
    },

    fetchCounters: debounce(500, false, async function() {
      await this.$nextTick()

      const { portsInViewport } = this
      const portsWithoutCounters = portsInViewport
        .filter(port => this.getCounter('ports', port) === NO_COUNTER)
      // console.table(portsWithoutCounters)
      return Promise.all(portsWithoutCounters.map(port => this.fetchCounter(port)))
    }),
  },
}
</script>

<style lang="sass" scoped>
// .IssueFilterPort
</style>
