<template>
  <IssueFilter
    class="IssueFilterCustom"
    :title="field.displayName"
    :dark="dark"
  >
    <template v-if="field.type === ISSUE_FIELD_TYPE.BOOLEAN">
      <IssueFilterCheckbox
        key="1"
        class="IssueFilterCustom__option"
        :value="checkboxOn"
        :counter="getCounter('true')"
        :dark="dark"
        @input="onBoolOnInput"
      >
        <span
          :class="{ 'white--text': dark }"
          v-text="$t('filter.Yes')"
        />
      </IssueFilterCheckbox>
      <IssueFilterCheckbox
        key="0"
        class="IssueFilterCustom__option"
        :value="checkboxOff"
        :counter="getCounter('false')"
        :dark="dark"
        @input="onBoolOffInput"
      >
        <span
          :class="{ 'white--text': dark }"
          v-text="$t('filter.No')"
        />
      </IssueFilterCheckbox>
    </template>
    <template v-else>
      <IssueFilterCheckbox
        v-for="option in field.allowedValues"
        :key="option.value"
        :value="model.includes(option.value)"
        :counter="getCounter(option.value)"
        :dark="dark"
        class="IssueFilterCustom__option"
        @input="toggleOption(option.value, $event)"
      >
        <span
          :class="{ 'white--text': dark }"
          v-text="option.displayValue"
        />
      </IssueFilterCheckbox>
    </template>
  </IssueFilter>
</template>

<script>
import * as R from 'ramda'

import { EMPTY_SET, ISSUE_FIELD_TYPE, BOOL_ON_VALUES, BOOL_OFF_VALUES } from '../constants'

import IssueCounter from '../store/orm/issueCounter'

import IssueFilter from '../layouts/IssueFilter'
import IssueFilterCheckbox from './IssueFilterCheckbox'

const NO_COUNTER = '…'

export default {
  name: 'IssueFilterCustom',

  components: {
    IssueFilter,
    IssueFilterCheckbox,
  },

  props: {
    projectId: { type: String, required: true },

    field: { type: Object, required: true },
    value: { type: Array, default: null },
    dark: { type: Boolean, default: false },

    filter: { type: [Object, Symbol], default: () => ({}) },
    additiveFilters: { type: Boolean, default: true },
  },

  data() {
    return {
      ISSUE_FIELD_TYPE,

      model: this.value ? [...this.value] : [],
    }
  },

  computed: {
    counters() {
      const { projectId, filter, additiveFilters } = this
      return IssueCounter.getOrDefault(projectId, filter, additiveFilters)
    },

    checkboxOn() {
      return this.model.some(v => BOOL_ON_VALUES.has(v))
    },

    checkboxOff() {
      return this.model.some(v => BOOL_OFF_VALUES.has(v))
    },
  },

  watch: {
    // v-model
    value(value) {
      const newModel = value ? [...value] : []
      if (R.equals(this.model, newModel)) return
      this.model = newModel
    },
    model(model) {
      const currenValue = this.value ? [...this.value] : []
      if (R.equals(currenValue, model)) return
      this.$emit('input', [...model])
    },
  },

  methods: {
    toggleOption(optionValue, isChecked) {
      if (isChecked) {
        if (!this.model.includes(optionValue)) this.model.push(optionValue)
      } else {
        this.model = this.model.filter(v => v !== optionValue)
      }
    },

    onBoolOnInput(isOn) {
      if (isOn) {
        if (!this.checkboxOn) this.model = ['true']
      } else {
        if (this.checkboxOn) {
          this.model = this.model.filter(v => !BOOL_ON_VALUES.has(v))
        }
      }
    },

    onBoolOffInput(isOn) {
      if (isOn) {
        if (!this.checkboxOff) this.model = ['false']
      } else {
        if (this.checkboxOff) {
          this.model = this.model.filter(v => !BOOL_OFF_VALUES.has(v))
        }
      }
    },

    getCounter(optValue) {
      if (this.filter === EMPTY_SET) return 0
      return this.counters.customFields?.[this.field.name]?.[optValue] ?? NO_COUNTER
    },
  },
}
</script>
