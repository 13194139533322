<template>
  <IssueFilter
    class="IssueFilterOverdue"
    :title="$t('filter.Overdue')"
    :dark="dark"
  >
    <IssueFilterCheckbox
      key="true"
      class="IssueFilterOverdue__option"
      :value="value.includes('true')"
      :counter="projectId && getCounter('true')"
      :dark="dark"
      @input="onInput('true', $event)"
    >
      <span
        :class="{ 'white--text': dark }"
        v-text="$t('filter.Overdue')"
      />
    </IssueFilterCheckbox>
    <IssueFilterCheckbox
      key="false"
      class="IssueFilterOverdue__option"
      :value="value.includes('false')"
      :counter="projectId && getCounter('false')"
      :dark="dark"
      @input="onInput('false', $event)"
    >
      <span
        :class="{ 'white--text': dark }"
        v-text="$t('filter.NotOverdue')"
      />
    </IssueFilterCheckbox>
    <IssueFilterCheckbox
      key="no-sla"
      class="IssueFilterOverdue__option"
      :value="value.includes('no-sla')"
      :counter="projectId && getCounter('no-sla')"
      :dark="dark"
      @input="onInput('no-sla', $event)"
    >
      <span
        :class="{ 'white--text': dark }"
        v-text="$t('filter.NoSla')"
      />
    </IssueFilterCheckbox>
  </IssueFilter>
</template>

<script>
import * as R from 'ramda'

import { EMPTY_SET } from '../constants'

import IssueCounter from '../store/orm/issueCounter'

import IssueFilter from '../layouts/IssueFilter'
import IssueFilterCheckbox from './IssueFilterCheckbox'

const NO_COUNTER = '…'

export default {
  name: 'IssueFilterOverdue',

  components: {
    IssueFilter,
    IssueFilterCheckbox,
  },

  props: {
    projectId: { type: String, default: null },
    value: { type: Array, default: undefined },
    dark: { type: Boolean, default: false },

    filter: { type: [Object, Symbol], default: () => ({}) },
    additiveFilters: { type: Boolean, default: true },
  },

  computed: {
    counters() {
      const { projectId, filter, additiveFilters } = this
      return IssueCounter.getOrDefault(projectId, filter, additiveFilters)
    },
  },

  methods: {
    getCounter(optValue) {
      if (this.filter === EMPTY_SET) return 0
      return this.counters.overdue?.[optValue] ?? NO_COUNTER
    },

    onInput(optionValue, isOn) {
      if (isOn) {
        this.$emit('input', R.uniq([...this.value, optionValue]))
      } else {
        this.$emit('input', this.value.filter(v => v !== optionValue))
      }
    },
  },
}
</script>
