<template>
  <div
    class="IssueFilter"
    :class="{ 'IssueFilter--dark': dark }"
  >
    <h6
      class="IssueFilter__title"
      :style="{ top: stickyTop }"
      v-text="title"
    />

    <slot />

    <v-btn
      v-if="showMoreButton"
      text
      block
      tile
      class="IssueFilter__more"
      @click="showMoreModel = !showMoreModel"
    >
      {{ showMoreModel ? showLessText : showMoreText }}
    </v-btn>
  </div>
</template>

<script>
import i18n from '../i18n'

export default {
  name: 'IssueFilter',

  props: {
    title: { type: String, required: true },
    showMoreButton: { type: Boolean, default: false },
    showMore: { type: Boolean, default: false }, // .sync
    showMoreText: { type: String, default: i18n.t('filter.ShowMore') },
    showLessText: { type: String, default: i18n.t('filter.ShowLess') },
    dark: { type: Boolean, default: false },
    stickyTop: { type: String, default: '0' },
  },

  data() {
    return {
      showMoreModel: this.showMore,
    }
  },

  watch: {
    // :show-more.sync
    showMore(showMore) {
      if (showMore !== this.showMoreModel) {
        this.showMoreModel = !!showMore
      }
    },
    showMoreModel(v) { this.$emit('update:showMore', v) },
  },
}
</script>

<style lang="sass" scoped>
.IssueFilter
  font-size: 14px
  font-weight: 400
  line-height: 24px
  letter-spacing: normal
  padding: 8px 0
  position: relative

  &__title
    height: 24px
    font-size: 14px
    line-height: 24px
    font-weight: 400
    color: #8B90A0
    background: white
    margin-bottom: 8px
    position: sticky
    top: 64px
    z-index: 1
    padding-left: 30px
    padding-right: 30px

  &--dark &__title
    color: rgba(255, 255, 255, .4)
    background: var(--v-darkBackground-base)

  &__more
    font-weight: 400
    color: var(--v-primary-base)
    position: sticky
    bottom: 0
    z-index: 1
    background: white

    ::v-deep .v-btn__content
      justify-content: flex-start
      padding-left: 30px - 17.777777777778px

  &--dark &__more
    color: var(--v-darkPrimary-base)
    background: var(--v-darkBackground-base)

    &:hover
      color: var(--v-darkWarning-base)
</style>
