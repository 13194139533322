<template>
  <IssueFilterCheckbox
    class="IssueFilterGroupsAndProjectsGroup"
    :dark="dark"
    :value="selected"
    @input="$event !== selected && $emit('toggle')"
  >
    <span :style="{ paddingLeft: (32 * levelTruncated) + 'px' }">
      {{ project.name }}
    </span>
  </IssueFilterCheckbox>
</template>

<script>
import IssueFilterCheckbox from './IssueFilterCheckbox'

export default {
  name: 'IssueFilterGroupsAndProjectsProject',

  components: {
    IssueFilterCheckbox,
  },

  props: {
    project: { type: Object, required: true },
    dark: { type: Boolean, default: false },
    level: { type: Number, default: 0 },
    selected: { type: Boolean, default: false },
  },

  computed: {
    levelTruncated() { return Math.min(this.level, 4) },
  },
}
</script>

<style lang="sass">
.IssueFilterGroupsAndProjectsGroup
  &__label
    max-width: 100%
    display: inline-block
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
</style>
