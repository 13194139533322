<template>
  <div class="IssueFilterGroupsAndProjectsGroup">
    <IssueFilterCheckbox
      class="IssueFilterGroupsAndProjectsGroup__item"
      :dark="dark"
      :value="selfSelected"
      :indeterminate="!selfSelected && someChildrenSelected"
      @input="onSelfInput"
    >
      <span
        class="IssueFilterGroupsAndProjectsGroup__label"
        :style="{ paddingLeft: (32 * levelTruncated) + 'px' }"
      >
        <span class="IssueFilterGroupsAndProjectsGroup__expand-icon-wrapper">
          <v-btn
            icon
            small
            @click.stop.prevent="expanded = !expanded"
            @mousedown.stop
            @keydown.stop
            @keyup.stop
            @touchstart.stop
          >
            <v-icon
              class="IssueFilterGroupsAndProjectsGroup__expand-icon"
              size="24"
              v-text="group.getIcon(expanded)"
            />
          </v-btn>
        </span>
        <span class="IssueFilterGroupsAndProjectsGroup__name">
          {{ group.name }}
        </span>
      </span>
    </IssueFilterCheckbox>

    <v-expand-transition>
      <div
        v-if="limitedItems.length"
        v-show="expanded"
        class="IssueFilterGroupsAndProjectsGroup__children"
      >
        <template v-for="item in limitedItems">
          <IssueFilterGroupsAndProjectsGroup
            v-if="item.isGroup"
            :key="item.id"
            class="IssueFilterGroupsAndProjectsGroup__child IssueFilterGroupsAndProjectsGroup__child--group"
            :group="item.group"
            :limit-children="item.limitChildren"
            :dark="dark"
            :level="level + 1"
            :any-parent-selected="selfSelected"
            :selected-group-ids="selectedGroupIds"
            :selected-project-ids="selectedProjectIds"
            @toggle-group="$emit('toggle-group', $event)"
            @toggle-project="$emit('toggle-project', $event)"
          />
          <IssueFilterGroupsAndProjectsProject
            v-else-if="item.isProject"
            :key="item.id"
            class="IssueFilterGroupsAndProjectsGroup__child IssueFilterGroupsAndProjectsGroup__child--project"
            :project="item.project"
            :dark="dark"
            :level="level + 1"
            :selected="selfSelected || selectedProjectIds.includes(item.id)"
            @toggle="$emit('toggle-project', { projectId: item.id })"
          />
        </template>
      </div>
    </v-expand-transition>
  </div>
</template>
<script>
import Project from '../store/orm/project'
import ProjectGroup from '../store/orm/projectGroup'

import IssueFilterCheckbox from './IssueFilterCheckbox'
import IssueFilterGroupsAndProjectsProject from './IssueFilterGroupsAndProjectsProject'

export default {
  name: 'IssueFilterGroupsAndProjectsGroup',

  components: {
    IssueFilterCheckbox,
    IssueFilterGroupsAndProjectsProject,
  },

  props: {
    group: { type: Object, required: true },
    level: { type: Number, default: 0 },
    limitChildren: { type: Number, default: null },
    dark: { type: Boolean, default: false },
    anyParentSelected: { type: Boolean, default: false },
    selectedGroupIds: { type: Array, default: () => [] }, // .sync
    selectedProjectIds: { type: Array, default: () => [] }, // .sync
  },

  data() {
    return {
      expanded: true,
    }
  },

  computed: {
    levelTruncated() { return Math.min(this.level, 4) },

    selfSelected() {
      const { anyParentSelected, selectedGroupIds, group: { id: groupId } } = this
      return anyParentSelected || selectedGroupIds.includes(groupId)
    },

    descendantItems() {
      const { group } = this

      return group
        .getSortedDescendants()
        .map((projectOrGroup) => {
          const isProject = projectOrGroup instanceof Project
          const isGroup = projectOrGroup instanceof ProjectGroup
          if (!isProject && !isGroup) throw new Error('TypeError')
          return {
            id: projectOrGroup.id,
            name: projectOrGroup.name,
            isProject,
            isGroup,
            project: isProject ? projectOrGroup : null,
            group: isGroup ? projectOrGroup : null,
            limitChildren: null,
          }
        })
    },

    limitedItems() {
      const { descendantItems, limitChildren } = this

      if (limitChildren == null) return descendantItems
      if (limitChildren <= 0) return []

      let limitLeft = limitChildren
      return descendantItems
        .map(item => {
          if (limitLeft <= 0) return null
          --limitLeft
          if (item.isProject) return item
          const rv = { ...item, limitChildren: limitLeft }
          limitLeft = Math.max(0, limitLeft - item.group.countDescendants())
          return rv
        })
        .filter(Boolean)
    },

    someChildrenSelected() {
      const { selfSelected, selectedProjectIds, selectedGroupIds } = this
      if (selfSelected) return true
      const groupHasSelectedChildren = (group) =>
        selectedGroupIds.includes(group.id) ||
        group.getProjectsQ().all().some(p => selectedProjectIds.includes(p.id)) ||
        group.getChildrenQ().all().some(subGroup => groupHasSelectedChildren(subGroup))
      return groupHasSelectedChildren(this.group)
    },
  },

  methods: {
    onSelfInput($event) {
      if ($event === this.selfSelected) return
      if (this.level === 0) {
        this.$emit('toggle')
      } else {
        this.$emit('toggle-group', { groupId: this.group.id })
      }
    },
  },
}
</script>

<style lang="sass">
.IssueFilterGroupsAndProjectsGroup
  $self: &

  &__item
    padding-left: 0 !important

  &__label
    display: flex
    align-items: center
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__expand-icon-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    margin-left: 30px
    margin-right: 8px
    flex: 0 0 24px

  &__expand-icon
    color: #A09EB9 !important

  & .v-btn
    &:hover, &:active, &:focus
      #{$self}__expand-icon
        color: #D3D6E2 !important

  &__name
    display: inline-block
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
</style>
