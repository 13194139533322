<template>
  <div
    v-ripple="{ class: 'app-ripple' }"
    class="IssueFilterCheckbox"
    :class="{
      'IssueFilterCheckbox--active': value,
      'IssueFilterCheckbox--dark': dark,
    }"
    tabindex="0"
    @click="model = !model"
    @keyup.enter="model = !model"
    @keyup.space="model = !model"
  >
    <span class="IssueFilterCheckbox__label">
      <slot />
    </span>
    <span
      v-if="counter != null"
      class="IssueFilterCheckbox__counter ml-auto"
      v-text="counter"
    />
    <v-simple-checkbox
      v-model="model"
      class="IssueFilterCheckbox__checkbox ml-2 mt-0 pt-0"
      :class="{
        'ml-2': counter != null,
        'ml-auto': counter == null,
      }"
      :color="dark ? 'darkPrimary' : 'primary'"
      :indeterminate="indeterminate"
      indeterminate-icon="mdi-checkbox-intermediate-variant"
      @click.stop="/* prevent parent click handler */"
    />
  </div>
</template>

<script>
export default {
  name: 'IssueFilterCheckbox',

  props: {
    value: { type: Boolean, default: false }, // v-model
    counter: { type: [String, Number], default: null },
    dark: { type: Boolean, default: false },
    indeterminate: { type: Boolean, default: false },
  },

  data() {
    return {
      model: this.value,
    }
  },

  watch: {
    // v-model
    value(v) { if (this.model !== v) this.model = v },
    model(v) { if (this.value !== v) this.$emit('input', v) },
  },
}
</script>

<style lang="sass" scoped>
.IssueFilterCheckbox
  cursor: pointer
  padding: 0 30px
  height: 32px
  line-height: 32px
  display: flex
  align-items: center

  & + &
    margin-top: 4px

  &:hover
    background: rgba(0, 0, 0, .15)

  &__label
    display: inline-block
    overflow: hidden
    height: 32px
    white-space: nowrap
    text-overflow: ellipsis
    flex-grow: 1
    padding-right: 20px

  &__checkbox
    margin-top: 0 !important
    margin-bottom: 0 !important

  &--dark &__checkbox
    ::v-deep input,
    ::v-deep .v-icon
      color: rgba(255, 255, 255, .6)

  &__counter
    color: #8B90A0

  &--dark &__counter
    color: rgba(255, 255, 255, .7)
</style>
