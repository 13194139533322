<template>
  <IssueFilter
    class="IssueFilterCheckboxScore"
    :title="title"
    :dark="dark"
    :sticky-top="stickyTop"
  >
    <IssueFilterCheckbox
      v-for="score in SCORE"
      :key="score.value"
      :value="model.includes(score.value)"
      :counter="projectId && getCounter(score.value)"
      :dark="dark"
      class="IssueFilterCheckboxScore__score"
      @input="setScore(score.value, $event)"
    >
      <span
        :class="{ 'white--text': dark }"
        v-text="$t(`${score.displayValue}`)"
      />
    </IssueFilterCheckbox>
  </IssueFilter>
</template>

<script>
import * as R from 'ramda'

import { SCORE } from '../constants'

import IssueCounter from '../store/orm/issueCounter'

import IssueFilter from '../layouts/IssueFilter'
import IssueFilterCheckbox from './IssueFilterCheckbox'

const NO_COUNTER = '…'

export default {
  name: 'IssueFilterCheckboxScore',

  components: {
    IssueFilter,
    IssueFilterCheckbox,
  },

  props: {
    projectId: { type: String, default: null },

    // e.g.: [1, 2]
    value: { type: Array, default: () => [] },

    filter: { type: [Object, Symbol], default: () => ({}) },
    additiveFilters: { type: Boolean, default: true },

    scoreProp: {
      type: String,
      required: true,
      validator: prop =>
        ['totalScore', 'criticalityScore', 'probabilityScore'].includes(prop),
    },

    // e.g.: 'Probability', 'Criticality'
    title: { type: String, required: true },
    dark: { type: Boolean, default: false },
    stickyTop: { type: String, default: '0' },
  },

  data() {
    return {
      SCORE,

      model: [...this.value].map(Number),
    }
  },

  computed: {
    counters() {
      const { projectId, filter, additiveFilters } = this
      return IssueCounter.getOrDefault(projectId, filter, additiveFilters)
    },
  },

  watch: {
    // v-model
    value(v) {
      if (R.equals(v, this.model)) return
      this.model = [...v].map(Number)
    },
    model(v) {
      if (R.equals(v, this.value)) return
      this.$emit('input', [...v])
    },
  },

  methods: {
    getCounter(status) {
      const { counters, scoreProp } = this
      return counters?.[scoreProp]?.[status] ?? NO_COUNTER
    },

    setScore(score, isSelected) {
      const { model } = this
      if (isSelected) {
        if (!model.includes(score)) model.push(score)
      } else {
        let ix = -1
        while ((ix = model.indexOf(score)) !== -1) model.splice(ix, 1)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
// .IssueFilterCheckboxScore
</style>
