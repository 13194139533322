<template>
  <IssueFilter
    class="IssueFilterCompleted"
    :title="$t('filter.Completed')"
    :dark="dark"
  >
    <IssueFilterCheckbox
      key="1"
      class="IssueFilterCompleted__option"
      :value="value.includes(true)"
      :counter="projectId && getCounter('true')"
      :dark="dark"
      @input="onBoolOnInput"
    >
      <span
        :class="{ 'white--text': dark }"
        v-text="$t('filter.Completed')"
      />
    </IssueFilterCheckbox>
    <IssueFilterCheckbox
      key="0"
      class="IssueFilterCompleted__option"
      :value="value.includes(false)"
      :counter="projectId && getCounter('false')"
      :dark="dark"
      @input="onBoolOffInput"
    >
      <span
        :class="{ 'white--text': dark }"
        v-text="$t('filter.NotCompleted')"
      />
    </IssueFilterCheckbox>
  </IssueFilter>
</template>

<script>
import * as R from 'ramda'

import { EMPTY_SET } from '../constants'

import IssueCounter from '../store/orm/issueCounter'

import IssueFilter from '../layouts/IssueFilter'
import IssueFilterCheckbox from './IssueFilterCheckbox'

const NO_COUNTER = '…'

export default {
  name: 'IssueFilterCompleted',

  components: {
    IssueFilter,
    IssueFilterCheckbox,
  },

  props: {
    projectId: { type: String, default: null },
    value: { type: Array, default: undefined },
    dark: { type: Boolean, default: false },

    filter: { type: [Object, Symbol], default: () => ({}) },
    additiveFilters: { type: Boolean, default: true },
  },

  computed: {
    counters() {
      const { projectId, filter, additiveFilters } = this
      return IssueCounter.getOrDefault(projectId, filter, additiveFilters)
    },
  },

  methods: {
    getCounter(optValue) {
      if (this.filter === EMPTY_SET) return 0
      return this.counters?.isCompleted?.[optValue] ?? NO_COUNTER
    },

    onBoolOnInput(isOn) {
      if (isOn) {
        this.$emit('input', R.uniq([...this.value, true]))
      } else {
        this.$emit('input', this.value.filter(v => v !== true))
      }
    },

    onBoolOffInput(isOn) {
      if (isOn) {
        this.$emit('input', R.uniq([...this.value, false]))
      } else {
        this.$emit('input', this.value.filter(v => v !== false))
      }
    },
  },
}
</script>
