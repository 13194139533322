<template>
  <IssueFilter
    v-if="allIps && allIps.length"
    class="IssueFilterIp"
    :title="$t('filter.IpAddresses')"
    :show-more-button="showExpandToggle"
    :show-more.sync="expand"
    :dark="dark"
    :sticky-top="stickyTop"
  >
    <IssueFilterCheckbox
      v-for="ip in visibleIps"
      :key="ip"
      v-intersect="(entries, observer) => onIntersect(ip, entries, observer)"
      :value="model.includes(ip)"
      :counter="projectId && getCounter(ip)"
      :dark="dark"
      class="IssueFilterIp__ip"
      @input="setIpSelected(ip, !model.includes(ip))"
    >
      <span
        class="text-truncate"
        :class="{ 'white--text': dark }"
        v-text="ip"
      />
    </IssueFilterCheckbox>
  </IssueFilter>
</template>

<script>
import axios from 'axios'
import * as R from 'ramda'
import { debounce } from 'throttle-debounce'

import { EMPTY_SET } from '../constants'
import { removeInPlace, unorderedPlainObjectHash } from '../helpers'

import IssueCounter from '../store/orm/issueCounter'

import IssueFilter from '../layouts/IssueFilter'
import IssueFilterCheckbox from './IssueFilterCheckbox'

const getVisibleSlice = (
  items,
  alwaysDisplayWhen = () => false,
  defaultNumber,
) => {
  const [alwaysVisible, rest] = R.partition(alwaysDisplayWhen, items)
  if (alwaysVisible.length >= defaultNumber) return alwaysVisible
  return [
    ...alwaysVisible,
    ...rest.slice(0, defaultNumber - alwaysVisible.length),
  ]
}

const DEFAULT_IP_LINES = 5
const NO_COUNTER = '…'

export default {
  name: 'IssueFilterIp',

  components: {
    IssueFilter,
    IssueFilterCheckbox,
  },

  props: {
    projectId: { type: String, required: true },

    // e.g.: ['0.0.0.0', '1.2.3.4']
    value: { type: Array, default: () => [] },

    filter: { type: [Object, Symbol], default: () => ({}) },
    additiveFilters: { type: Boolean, default: true },

    dark: { type: Boolean, default: false },
    stickyTop: { type: String, default: '0' },
  },

  data() {
    return {
      model: [...this.value],

      expand: false,
      ipsInViewport: [],
    }
  },

  computed: {
    filterHash() {
      const { filter } = this
      return filter && unorderedPlainObjectHash(filter)
    },

    counters() {
      const { projectId, filter, additiveFilters } = this
      return IssueCounter.getOrDefault(projectId, filter, additiveFilters)
    },

    allIps() {
      const { $store, projectId } = this
      return $store.getters['projectsSettings/allIps'](projectId)
    },

    collapsedIps() {
      const { allIps } = this
      return allIps && getVisibleSlice(allIps, this.isSelected, DEFAULT_IP_LINES)
    },

    visibleIps() {
      const { allIps, collapsedIps, expand } = this
      if (!allIps || !collapsedIps) return null

      const toShow = expand
        ? allIps
        : collapsedIps
      return R.sortWith([
        R.descend(this.isSelected),
      ], toShow)
    },

    showExpandToggle() {
      const { allIps, collapsedIps } = this

      if (!allIps?.length || !collapsedIps) return null
      return allIps.length > collapsedIps.length
    },
  },

  watch: {
    // v-model
    value(v) {
      if (R.equals(v, this.model)) return
      this.model = [...v]
    },
    model(v) {
      if (R.equals(v, this.value)) return
      this.$emit('input', [...v])
    },

    filterHash: 'fetchCounters',

    ipsInViewport: {
      handler: 'fetchCounters',
      immediate: true,
    },

    visibleIps(ips) {
      if (!ips) return
      this.ipsInViewport = R.uniq([
        ...this.collapsedIps,
        ...this.ipsInViewport
          .filter(ip => ips.includes(ip)),
      ])
    },

    async expand(isOpen) {
      if (isOpen) {
        await this.$nextTick()
        this.$el.querySelector('.IssueFilter__title') // eslint-disable-line
          ?.scrollIntoView?.(true)
      }
    },
  },

  methods: {
    getCounter(ip) {
      if (this.filter === EMPTY_SET) return 0
      return this.counters.ips?.[ip] ?? NO_COUNTER
    },

    setIpSelected(ip, isSelected) {
      const { model } = this
      if (isSelected) {
        if (!model.includes(ip)) model.push(ip)
      } else {
        this.model = R.reject(R.equals(ip), model)
      }
    },

    onIntersect(ip, entries/*, observer */) {
      const { ipsInViewport } = this
      const target = entries[0]

      if (!target.isIntersecting) {
        removeInPlace(ipsInViewport, ip)
      } else {
        if (!ipsInViewport.includes(ip)) ipsInViewport.push(ip)
      }
    },

    isSelected(ip) { return this.model.includes(ip) },

    async fetchCounter(ip) {
      const { projectId, filter, additiveFilters: additive } = this
      if (filter === EMPTY_SET) return
      return await IssueCounter.dispatch(
        '$countIp',
        { projectId, ip, filter, additive },
      )
        .catch((e) => axios.isCancel(e) ? console.info(e) : console.error(e))
    },

    fetchCounters: debounce(500, false, async function() {
      await this.$nextTick()

      const { ipsInViewport } = this
      const ipsWithoutCounters = ipsInViewport
        .filter(ip => this.getCounter('ips', ip) === NO_COUNTER)
      // console.table(ipsWithoutCounters)
      return Promise.all(ipsWithoutCounters.map(ip => this.fetchCounter(ip)))
    }),
  },
}
</script>

<style lang="sass" scoped>
// .IssueFilterIp
</style>
